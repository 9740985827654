.job {
    font-size: 2em !important;
    font-weight: 500 !important;
    line-height: 1.2em;
}

@media all and (max-width: 1040px) {
    .job {
        font-size: 1.5em !important;
    }
}