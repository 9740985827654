.gallery-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
}

.gallery-container a {
    flex: 0 0 33.33333%;
    max-width: 33.33333%;
    padding: 5px;
}

@media all and (max-width: 1200px) {
    .gallery-container a {
        flex: 0 50%;
        max-width: 50%;
        padding: 5px;
    }
}

@media all and (max-width: 800px) {
    .gallery-container a {
        flex: 100%;
        max-width: 100%;
        padding: 5px;
    }
}

.lg-video-play-button {
    background-color: rgba(45, 45, 45, 0) !important;
    background: #ffffff00;
    padding: 25% 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.lg-video-play-button:focus {
    outline: none !important;
}

.lg-video-play-icon {
    background-color: rgba(45, 45, 45, 0.7) !important;
    padding: 10px;
    width: 100px;
    height: 50px;
    border-radius: 10px;
}

.lg-video-play-icon .lg-video-play-icon-inner,
.lg-video-play-icon {
    fill: rgb(255, 255, 255) !important;
}

.lg-video-play-icon-bg {
    fill: rgba(62, 62, 62, 0) !important;
    width: 0 !important;
    height: 0 !important;
}

.aron {
    background-color: red;
    height: 200px !important;
}

div.vjs-control-bar>div.vjs-progress-control.vjs-control>div>div.vjs-play-progress.vjs-slider-bar {
    background-color: red !important;
}

div.vjs-control-bar>button.vjs-picture-in-picture-control.vjs-control.vjs-button {
    display: none !important;
}

video::-internal-media-controls-overlay-cast-button {
    display: none;
}

img {
    pointer-events: none;
}

.modal-video-body {
    /*max-width: 1600px !important;*/
}

.modal-video-movie-wrap {
    width: 1600px !important;
}

.noSelect {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.noSelect:focus {
    outline: none !important;
}

.skillScroll {
    text-align: center !important;
    width: 300px;
    //text-transform: uppercase;
}

.skillScroll:hover {
    color: #767676 !important
}

@media all and (max-width: 900px) {
    li h3 {
        font-size: 1em !important;
    }
}

a.contactLink:hover {
    text-decoration: underline !important;
    color: rgb(255, 246, 187) !important;
}

.contactSocial {
    size: 300px !important;
}

.shane_tm_testimonials {
    padding: 100px 0 !important;
}

dt {
    font-weight: bold;
}

dd {
    background: none no-repeat left top;
    padding-left: 50px;
}

dl>dt>dd.workDate {
    font-weight: bold !important;
    font-size: 1.15em !important;
}

.education-border {
    border-left: 1px solid rgba(137, 130, 247, 0.2);
}

dt {
    margin-top: 10px !important;
}

.logo a {
    font-size: 1.3em;
    font-weight: 700;
    text-decoration: none;
    color: #000 !important;
    text-transform: uppercase !important;
}

.close_menu {
    cursor: pointer !important;
}

.contactBoxes {
    padding: 30px 10px !important;
}

.lg-sub-html {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.3pt;
}

.shane_tm_text {
    margin-top: 10px !important;
    a {
        text-decoration: none;
        color: #000 !important;
        font-weight: 700;
        &:hover {
            text-decoration: underline;
        }
    }
}

.skillButton {
    margin: 35px 0px 0 0 !important;
}